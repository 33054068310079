import { CanvasFile } from "@/models/wb/canvasFile.interface";
import * as wbFileService from "@/services/wb/wbFileService";
import { ModelViewerElement } from "@google/model-viewer/lib/model-viewer";
import { Material } from "@google/model-viewer/lib/features/scene-graph/material";
import {
  MaterialMetadata,
  RenderedMaterial
} from "@/models/wb/solidMaterial.interface";
import loggerService from "@/services/loggerService";
import { emptyFileId } from "@/services/configService";

async function generatePreview(
  url: string,
  file: CanvasFile
  // cameraOrbit?: string
): Promise<string> {
  // creation of programmatic model-viewer to temporarily render 3d object
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  const modelViewer = document.createElement("model-viewer") as any;
  if (modelViewer) {
    //setting basic properties to pass to the component as well as hiding it from user
    modelViewer.id = url;
    modelViewer.src = url;
    modelViewer.style = `width:800px; height:450px; position:fixed; z-index: 0; top:0px;`;
    if (file.details?.sphericalCoordinates) {
      modelViewer.cameraOrbit = file.details.sphericalCoordinates;
    }
  }
  document.body.appendChild(modelViewer);

  modelViewer.addEventListener("before-render", function() {
    if (file.details?.materials?.length) {
      const materials = createRenderedMaterials(
        modelViewer.model?.materials,
        file.details?.materials || []
      );
      initMaterials(materials);
    }
  });

  //use of promise as to wait for the model viewer method to create a base64 url to pass to konva
  const urlPromise = new Promise(resolve => {
    modelViewer.addEventListener("poster-dismissed", function() {
      if (modelViewer.toDataURL) resolve(modelViewer.toDataURL("img/png", 0));
    });
  });
  // once the promise is consumed, we encapsulate the base64 url in a variable
  const glbUrl = (await urlPromise) as string;
  //we delete from the dom the temporary model viewer, data now in glbUrl
  modelViewer.parentNode.removeChild(modelViewer);
  //since we return a base64 url, like in the other functions, we don't need to create a different logic for konva
  return glbUrl;
}

export async function GetGlbElementDataUrl(file: CanvasFile): Promise<string> {
  if (file.fileId == emptyFileId) return "";
  const objectUrl = await wbFileService.fetchFile(file.fileId);
  return await generatePreview(objectUrl, file);
}

export function rgba2hex(arr: number[]): string {
  const res =
    "#" +
    arr
      .map(number => linearToSrgb(number))
      .map(number => Math.round(number * 255).toString(16)) // Converts alpha to 255 number
      .map(string => (string.length === 1 ? "0" + string : string)) // Adds 0 when length of one number is 1
      .join(""); // Puts the array to togehter to a string
  return hexa2hex(res);
}

export function hexa2hex(col: string): string {
  if (col.length > 7) {
    return col.slice(0, -2);
  }
  return col;
}

function linearToSrgb(val: number): number {
  return val < 0.0031308 ? val * 12.92 : 1.055 * Math.pow(val, 0.41666) - 0.055;
}

// export function hexa2rgba(hex: string): string {
//   const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})/i.exec(
//     hex
//   );
//   const res = result
//     ? "rgba(" +
//       parseInt(result[1], 16) +
//       "," +
//       parseInt(result[2], 16) +
//       "," +
//       parseInt(result[3], 16) +
//       "," +
//       parseInt(result[4], 16) +
//       ")"
//     : "";
//   return res;
// }

export async function takeSnapShot(model: ModelViewerElement): Promise<string> {
  return model.toDataURL("image/png");
}

export function renderedMaterialDeserializer(
  m: Material,
  d?: MaterialMetadata
): RenderedMaterial | undefined {
  try {
    return {
      fullItem: m,
      name: m.name,
      index: m.index,
      metallicFactor:
        d?.metallicFactor != undefined
          ? d.metallicFactor
          : m.pbrMetallicRoughness.metallicFactor,
      roughnessFactor:
        d?.roughnessFactor != undefined
          ? d.roughnessFactor
          : m.pbrMetallicRoughness.roughnessFactor,
      hexColor:
        d?.hexColor != undefined
          ? hexa2hex(d.hexColor)
          : rgba2hex(m.pbrMetallicRoughness.baseColorFactor)
    };
  } catch (e) {
    loggerService.warn("Error deserializing material " + m.name);
  }
}

export function createRenderedMaterials(
  materials: Material[],
  metaData: MaterialMetadata[]
): RenderedMaterial[] {
  return materials
    .map(mat => {
      let data;
      if (metaData) {
        data = metaData.find(meta => meta.index == mat.index);
      }
      const res = renderedMaterialDeserializer(mat, data);
      return res;
    })
    .filter(m => {
      return m != undefined;
    }) as RenderedMaterial[];
}

export function applyColor(mat: RenderedMaterial): void {
  // const res = hexa2rgba(mat.hexColor);
  mat.fullItem.pbrMetallicRoughness.setBaseColorFactor(mat.hexColor);
}

export function applyMetal(mat: RenderedMaterial): void {
  mat.fullItem.pbrMetallicRoughness.setMetallicFactor(mat.metallicFactor);
}

export function applyRoughness(mat: RenderedMaterial): void {
  mat.fullItem.pbrMetallicRoughness.setRoughnessFactor(mat.roughnessFactor);
}

export function initMaterials(materials: RenderedMaterial[]): void {
  materials.map(mat => {
    applyColor(mat);
    applyMetal(mat);
    applyRoughness(mat);
  });
}
