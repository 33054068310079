import Konva from "konva";
import { SocketStroke, Stroke } from "@/models/wb/stroke.interface";
import { CanvasFile } from "@/models/wb/canvasFile.interface";
import store from "@/store";
import * as wbFileService from "@/services/wb/wbFileService";
import { Tool } from "@/models/wb/tool.interface";
import { v4 as uuidv4 } from "uuid";
import { SocketPointsSerializer } from "@/models/wb/point.interface";
import { GetTextElementDataUrl } from "@/services/wb/elements/wbTexElemets.service";
import { GetWebElementDataUrl } from "@/services/wb/elements/wbWebElement.service";
import { GetVideoElementDataUrl } from "@/services/wb/elements/wbVideoElement.service";
import { GetGlbElementDataUrl } from "@/services/wb/elements/wbGlbElement.service";
import { GetPdfElementDataUrl } from "@/services/wb/elements/wbPdfElements.service";
import { GetImageElementDataUrl } from "@/services/wb/elements/wbImageElement.service";
import { emptyImage } from "@/services/wb/elements/placeholders";

export function Stroke2Line(
  stroke: Stroke,
  offset = { x: 0, y: 0 },
  scale = { x: 1, y: 1 },
  rotation = 0
): Konva.Line {
  const line = new Konva.Line({
    stroke: stroke.color,
    strokeWidth: stroke.width,
    points: stroke.flattenedPoints,
    bezier: false,
    lineCap: "round",
    lineJoin: "round",
    tension: 0.5,
    draggable: false,
    opacity: stroke.mode !== "draw" ? 0.5 : 1,
    hitStrokeWidth: 0,
    offset: offset,
    scale: scale,
    rotation: rotation
  });

  const points = line.points();
  if (points.length <= 2) {
    // hack force draw single points
    const additionalPoint = [
      points[points.length - 2],
      points[points.length - 1]
    ];
    line.points(points.concat(additionalPoint));
  }
  return line;
}

export function FileStroke2Line(file: CanvasFile, stroke: Stroke): Konva.Line {
  return Stroke2Line(
    stroke,
    {
      x: -file.manipulation.x / file.manipulation.scaleX,
      y: -file.manipulation.y / file.manipulation.scaleY
    },
    { x: file.manipulation.scaleX, y: file.manipulation.scaleY },
    file.manipulation.rotation
  );
}

export function FileStrokes2Lines(file: CanvasFile): Konva.Line[] {
  if (file.pagesStrokes) {
    return file.pagesStrokes[file.currentPage].map((stroke: Stroke) => {
      return FileStroke2Line(file, stroke);
    });
  }
  return [];
}

export async function File2KonvaNode(
  file: CanvasFile,
  active = true,
  preloadedUrl = undefined as string | undefined | Blob
): Promise<Konva.Image> {
  let url = preloadedUrl;
  let fillColor = "";
  if (file.type === "url") fillColor = "#cbc9c9";
  if (file.type === "post-it") {
    url = emptyImage;
    if (file.details?.background) {
      fillColor = file.details.background;
    } else {
      fillColor = "#f4eeb6";
    }
  } else if (!url) {
    url = await GetElementDataUrl(file);
  }
  return new Promise((resolve, reject) => {
    try {
      Konva.Image.fromURL(url, (image: Konva.Image) => {
        image.setAttrs({
          uuid: file.uuid,
          x: 0,
          y: 0,
          scaleX: file.manipulation.scaleX,
          scaleY: file.manipulation.scaleY,
          rotation: file.manipulation.rotation,
          width: file.size.width,
          height: file.size.height,
          offset: {
            x: -file.manipulation.x / file.manipulation.scaleX,
            y: -file.manipulation.y / file.manipulation.scaleY
          },
          draggable: store.getters["conference/isWriter"],
          fill: fillColor
        });
        if (active) {
          const stageReference = store.getters["canvas/stage"];
          image.on("mouseover", () => {
            stageReference.container().style.cursor = "default";
          });
          image.on("mouseout", () => {
            stageReference.container().style.cursor = "inherit";
          });
          image.on("mouseup touchend", () => {
            store.dispatch("wb/files/selectImage", image);
          });
          image.on("dblclick", () => {
            store.dispatch("wb/files/focusImage");
          });
          image.on("dragstart", () =>
            store.dispatch("wb/files/selectImage", image)
          );
          image.on("dragmove", async () => {
            wbFileService.onTransformElement();
          });
          image.on("dragend", async () => {
            wbFileService.onTransformElement();
          });
        }
        resolve(image);
      });
    } catch (err) {
      reject(err);
    }
  });
}

async function GetElementDataUrl(file: CanvasFile): Promise<string> {
  switch (file.type) {
    case "pdf":
    case "document":
      return await GetPdfElementDataUrl(file);
    case "url":
      return await GetWebElementDataUrl(file);
    case "text":
      return await GetTextElementDataUrl(file);
    case "glb":
      return await GetGlbElementDataUrl(file);
    case "video":
      return await GetVideoElementDataUrl(file);
    case "image":
      return await GetImageElementDataUrl(file);
    default:
      throw new Error(`Unhandled file type: ${file.type}`);
  }
}

export function strokeSerializer(
  input: Stroke,
  tool: Tool,
  size: { width: number; height: number },
  fileId: string
): SocketStroke {
  let drawMode = 0;
  if (tool === "eraser") drawMode = 13;
  if (tool === "marker") drawMode = 20;
  return {
    Id: uuidv4(),
    P: input?.points?.map(SocketPointsSerializer),
    R: `${size.width},${size.height}`,
    S: 0,
    M: drawMode,
    D: `${input?.width},${input?.height}`,
    C: input?.color.replace("#", "#FF"),
    T: fileId
  };
}
