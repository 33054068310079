import { Stroke, StrokeDeserializer } from "@/models/wb/stroke.interface";
import { Size, SizeDeserializer } from "@/models/wb/size.interface";
import store from "@/store";
import { getFileTypeByName, fetchFile } from "@/services/wb/wbFileService";
import Konva from "konva";
import { Page } from "@/models/wb/page.interface";
import { BoardElement } from "@/models/wb/boardElement.interface";
import { MaterialMetadata } from "@/models/wb/solidMaterial.interface";
import { emptyFileId } from "@/services/configService";

// the KEY int represent the page number
type PageStrokes = { [key: number]: Stroke[] };

export interface FileDetails {
  //TODO: this need a full refactor
  foreground?: string;
  background?: string;
  text?: string;
  sphericalCoordinates?: string;
  materials?: MaterialMetadata[];
}

export type fileTypes =
  | "pdf"
  | "image"
  | "text"
  | "glb"
  | "video"
  | "post-it"
  | "url"
  | "document";

export interface FileManipulation {
  size?: string;
  rotation: number;
  scaleX: number;
  scaleY: number;
  x: number;
  y: number;
}

export interface CanvasFile {
  uuid: string;
  fileId: string;
  pdfFileId: string;
  name: string;
  meetingId: string;
  size: Size;
  pagesStrokes?: PageStrokes;
  manipulation: FileManipulation;
  type: fileTypes;
  currentPage: number;
  inFullscreen?: boolean;
  filePagesQuantity?: number;
  details: FileDetails;
}

export interface SocketFileManipulation {
  Key?: string;
  ManipulationData: {
    Rotation: number;
    X: number;
    Y: number;
    ScaleX: number;
    ScaleY: number;
  };
  Name: string;
  ContainerSize: string;
  Size: string;
  State?: string;
  ZIndex: number;
  CurrentIndex: number;
  InFullscreen: boolean;
}

export function isValidDocumentMimeType(fileName: string): boolean {
  const allowedTypes = [
    ".doc",
    // ".dot",
    ".docx",
    // ".dotx",
    // ".docm",
    // ".dotm",
    ".xls",
    // ".xlt",
    // ".xla",
    ".xlsx",
    // ".xltx",
    // ".xlsm",
    // ".xltm",
    // ".xlam",
    // ".xlsb",
    ".pdf",
    ".ppt",
    // ".pot",
    // ".pps",
    // ".ppa",
    ".pptx"
    // ".potx",
    // ".ppsx",
    // ".ppam",
    // ".pptm",
    // ".potm",
    // ".ppsm"
  ];
  const mimeTypes = allowedTypes.filter(ext => fileName.indexOf(ext) > 0);
  return mimeTypes.length > 0;
}

export function isValidVideoMimeType(fileName: string): boolean {
  const allowedTypes = [
    ".flv",
    ".mp4",
    ".m3u8",
    ".ts",
    ".3gp",
    ".mov",
    ".avi",
    ".wmv"
  ];
  const mimeTypes = allowedTypes.filter(ext => fileName.indexOf(ext) > 0);
  return mimeTypes.length > 0;
}

export function isValidImageMimeType(fileName: string): boolean {
  const allowedTypes = [".jpeg", ".jpg", ".png", ".svg", ".gif"];
  const mimeTypes = allowedTypes.filter(ext => fileName.indexOf(ext) > 0);
  return mimeTypes.length > 0;
}

export function isValidFile(fileName: string): boolean {
  if (
    isValidDocumentMimeType(fileName) ||
    isValidVideoMimeType(fileName) ||
    isValidImageMimeType(fileName) ||
    fileName.indexOf("glb") > 0 ||
    fileName.indexOf("txt") > 0
  ) {
    return true;
  }
  store.commit("notifications/displayNotification", {
    message: "invalid file " + fileName,
    type: "error"
  });
  return false;
}

function PagesStrokesDeserializer(
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  rawPageStrokes: any,
  bounds: Size
): PageStrokes | undefined {
  const entries = Object.entries(rawPageStrokes);
  if (entries.length) {
    return entries.reduce((result, entry) => {
      return (
        // eslint-disable-next-line
        // @ts-ignore
        (result[parseInt(entry[0]) + 1] = entry[1]?.map(rawStroke => {
          if (rawStroke.StylusPoints?.length) {
            //REMOVE OUT OF BOUNDS POINTS
            rawStroke.StylusPoints = rawStroke.StylusPoints.filter(
              /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
              (point: any) => {
                return (
                  point.X > 0 &&
                  point.X < bounds.width &&
                  point.Y > 0 &&
                  point.Y < bounds.height
                );
              }
            );
          }
          return StrokeDeserializer(rawStroke);
        })),
        result
      );
    }, {});
  }
  return undefined;
}

export const cleanHexColor = (color: string): string => {
  // HACK change malformed 8-digits hex colors from android/pod
  if (color.length > 7) {
    const misplacedAlpha = color.slice(1, 3);
    color = `#${color.slice(3)}${misplacedAlpha}`;
  }
  return color;
};

const reverseHexColor = (color: string): string => {
  // HACK change malformed 8-digits hex colors from android/pod
  if (color.length == 9) {
    const misplacedAlpha = color.slice(-2);
    return color.slice(0, 1) + misplacedAlpha + color.slice(1, -2);
  }
  return color;
};

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export async function DetailsDeserializer(input: any): Promise<FileDetails> {
  if (input == undefined) return {};
  const parsedState = input.State ? JSON.parse(input.State) : undefined;
  const type = input.Name ? getFileTypeByName(input.Name) : "";
  let text =
    input.FileId &&
    input.FileId !== emptyFileId &&
    (type == "text" || type == "url")
      ? await fetchFile(input.FileId, true)
      : "";
  if (parsedState?.text) {
    text = parsedState.text;
  }
  //TODO: needs refactor
  if (parsedState?.Address) {
    text = parsedState.Address;
  }

  return {
    foreground: parsedState?.Foreground
      ? cleanHexColor(parsedState.Foreground)
      : undefined,
    background: parsedState?.Background
      ? cleanHexColor(parsedState.Background)
      : undefined,
    text: text,
    sphericalCoordinates: parsedState?.SphericalCoordinates
      ? parsedState.SphericalCoordinates
      : "auto",
    materials: parsedState?.Materials ? parsedState.Materials : []
  };
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export async function ElementDeserializer(input: any): Promise<CanvasFile> {
  if (input.ManipulationData) {
    // let type: string;

    const type = getFileTypeByName(input.Name);

    const currentPage =
      input.CurrentIndex != undefined ? parseInt(input.CurrentIndex) + 1 : 0;
    const details = await DetailsDeserializer(input);
    const shape = {
      uuid: input.uuid || "",
      fileId: input.FileId || "",
      pdfFileId: input.PdfFileId || "",
      name: input.Name || "",
      meetingId: input.MeetingId || "",
      size: SizeDeserializer(input.Size.toString()),
      manipulation: {
        rotation: input.ManipulationData.Rotation || 0,
        x: input.ManipulationData.X || 0,
        y: input.ManipulationData.Y || 0,
        scaleX: input.ManipulationData.ScaleX || 0,
        scaleY: input.ManipulationData.ScaleY || 0
      },
      type: type,
      currentPage: currentPage,
      inFullscreen: input.InFullscreen,
      filePagesQuantity: 1,
      details
    } as CanvasFile;
    shape.pagesStrokes = PagesStrokesDeserializer(input.Strokes, shape.size);
    return shape;
  } else {
    throw new Error(
      "ElementDeserializer error, missing ManipulationData or Strokes"
    );
  }
}

export async function fileManipulationDeserializer(
  elementKey: string,
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  data: any
): Promise<Partial<CanvasFile>> {
  const currentPage =
    data.CurrentIndex != undefined ? parseInt(data.CurrentIndex) + 1 : 0;
  const details = await DetailsDeserializer(data);
  return {
    uuid: elementKey || "",
    fileId: "",
    pdfFileId: "",
    name: "",
    meetingId: "",
    size: SizeDeserializer(data.Size.toString()),
    manipulation: {
      rotation: data.ManipulationData.Rotation || 0,
      x: data.ManipulationData.X || 0,
      y: data.ManipulationData.Y || 0,
      scaleX: data.ManipulationData.ScaleX || 0,
      scaleY: data.ManipulationData.ScaleY || 0
    },
    type: "image",
    currentPage: currentPage,
    inFullscreen: data.InFullscreen,
    details: details
  };
}

function fixRotationCords(x: number, y: number, rotation: number): number[] {
  if (rotation !== 0) {
    const radians = (rotation / 180) * Math.PI;
    return [
      Math.cos(radians) * x + Math.sin(radians) * y,
      Math.cos(radians) * y - Math.sin(radians) * x
    ];
  }
  return [x, y];
}

// :{ Foreground: string, Background: string }
//TODO: needs a full refactor
function DetailsSerializer(input?: FileDetails): string | undefined {
  if (!input) return undefined;
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  const res = {} as any;
  if (input.foreground && input.background) {
    res.Foreground = input.foreground ? reverseHexColor(input.foreground) : "";
    res.Background = input.background ? reverseHexColor(input.background) : "";
  }
  if (input.sphericalCoordinates) {
    res.SphericalCoordinates = input.sphericalCoordinates;
  }
  if (input.materials) {
    res.Materials = input.materials;
  }
  return JSON.stringify(res);
}

export function image2Manipulation(image: Konva.Image): FileManipulation {
  let [x, y] = fixRotationCords(
    image.attrs.x,
    image.attrs.y,
    image.attrs.rotation
  );
  x = x - image.attrs.offsetX * image.attrs.scaleX;
  y = y - image.attrs.offsetY * image.attrs.scaleY;
  return {
    // size?: string;
    rotation: image.attrs.rotation,
    scaleX: image.attrs.scaleX,
    scaleY: image.attrs.scaleY,
    x: x,
    y: y
  };
}

export function fileManipulationSerializer(
  element: BoardElement,
  page: Page
): SocketFileManipulation {
  const manipulation = image2Manipulation(element.image);
  const state = DetailsSerializer(element.file.details);

  return {
    Key: element.file.uuid,
    ManipulationData: {
      Rotation: manipulation.rotation,
      X: manipulation.x,
      Y: manipulation.y,
      ScaleX: manipulation.scaleX,
      ScaleY: manipulation.scaleY
    },
    Name: element.file.name || "",
    ContainerSize: `${page.size?.width},${page.size?.height}`,
    Size: `${element.image.attrs.width},${element.image.attrs.height}`,
    State: state,
    ZIndex: 0,
    CurrentIndex: element.file.currentPage - 1,
    InFullscreen: element.file.inFullscreen as boolean
  };
}
