import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "@/components/pages/Home.vue";
import Rooms from "@/components/pages/Rooms.vue";
import NewRoom from "@/components/pages/NewRoom.vue";
import SignIn from "@/components/pages/SignIn.vue";
import SignInForm from "@/components/pages/SignInForm.vue";
import SignUp from "@/components/pages/SignUp.vue";
import InviteSignup from "@/components/pages/InviteSignup.vue";
import Confirm from "@/components/pages/Confirm.vue";
import ConfirmLegacy from "@/components/pages/ConfirmLegacy.vue";
import ConfirmInvitationLegacy from "@/components/pages/ConfirmInvitationLegacy.vue";
import ForgotPass from "@/components/pages/ForgotPass.vue";
import ResetPass from "@/components/pages/ResetPass.vue";
import ResetPassLegacy from "@/components/pages/ResetPassLegacy.vue";
import GuestJoin from "@/components/pages/GuestJoin.vue";
import RoomDetail from "@/components/pages/RoomDetail.vue";
import Conference from "@/components/pages/Conference.vue";
import Profile from "@/components/pages/Profile.vue";
import store from "@/store";
import { designSystemRoutes } from "@/router/designSystemRoutes";

Vue.use(VueRouter);

let routes: Array<RouteConfig> = [
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp
  },
  {
    path: "/invite-signup",
    name: "InviteSignUp",
    component: InviteSignup
  },
  {
    path: "/presignin",
    name: "SignIn",
    component: SignIn
  },
  {
    path: "/signin",
    name: "SignInForm",
    component: SignInForm
  },
  {
    path: "/confirm",
    name: "Confirm",
    component: Confirm
  },
  {
    path: "/confirmlegacy",
    name: "ConfirmLegacy",
    component: ConfirmLegacy
  },
  {
    path: "/confirminvitationlegacy",
    name: "ConfirmInvitationLegacy",
    component: ConfirmInvitationLegacy
  },
  {
    path: "/forgot",
    name: "ForgotPass",
    component: ForgotPass
  },
  {
    path: "/reset-password",
    name: "ResetPass",
    component: ResetPass
  },
  {
    path: "/reset-passwordlegacy",
    name: "ResetPassLegacy",
    component: ResetPassLegacy
  },
  {
    path: "/auth*", // legacy redirect
    redirect: "/signin"
  },
  {
    path: "/webinar", // legacy redirect
    redirect: "/join"
  },
  {
    path: "/",
    name: "Home",
    meta: {
      requiresAuth: true
    },
    component: Home,
    beforeEnter: (to, from, next) => {
      const rooms = store.getters["room/roomList"];
      if (!rooms || !rooms.length) {
        store.dispatch("room/fetchRooms");
      }
      next();
    }
  },
  {
    path: "/profile",
    name: "Profile",
    meta: {
      requiresAuth: true
    },
    component: Profile
  },
  {
    path: "/room",
    name: "Rooms",
    meta: {
      requiresAuth: true
    },
    component: Rooms,
    beforeEnter: (to, from, next) => {
      store.dispatch("room/clearActiveRoom");
      const rooms = store.getters["room/roomList"];
      if (!rooms || !rooms.length) {
        store.dispatch("room/fetchRooms");
      }
      next();
    }
  },
  {
    path: "/room/new",
    name: "NewRoom",
    meta: {
      requiresAuth: true
    },
    component: NewRoom
  },
  {
    path: "/room/g/:id",
    name: "RoomDetail",
    meta: {
      requiresAuth: true
    },
    component: RoomDetail,
    beforeEnter: (to, from, next) => {
      store.dispatch("room/fetchRoom", { roomId: to.params.id, single: false });
      next();
    }
  },
  // {
  //   path: "/room/s/:id",
  //   name: "RoomDetailSingle",
  //   meta: {
  //     requiresAuth: true
  //   },
  //   component: RoomDetail,
  //   props: { single: true },
  //   beforeEnter: (to, from, next) => {
  //     store.dispatch("room/fetchRoom", { roomId: to.params.id, single: true });
  //     next();
  //   }
  // },
  {
    path: "/room/g/:id/session",
    name: "Conference",
    meta: {
      requiresAuth: true
    },
    component: Conference,
    beforeEnter: (to, from, next) => {
      store.dispatch("room/fetchRoom", { roomId: to.params.id, single: false });
      next();
    }
  },
  {
    path: "/room/s/:id/session",
    name: "ConferenceSingle",
    meta: {
      requiresAuth: true
    },
    component: Conference,
    props: { single: true },
    beforeEnter: (to, from, next) => {
      store.dispatch("room/fetchRoom", { roomId: to.params.id, single: true });
      next();
    }
  },
  {
    path: "/room*",
    redirect: "/room"
  },
  {
    path: "*",
    redirect: "/"
  }
];

if (process.env.VUE_APP_GUEST_ENABLED === "true") {
  //enable guest join routes
  routes.push({
    path: "/join",
    name: "GuestJoin",
    component: GuestJoin
  });
  routes.push({
    path: "/join/session",
    name: "ConferenceGuest",
    component: Conference,
    props: { guest: true }
  });
}

if (process.env.NODE_ENV !== "production") {
  //enable design system routes only for development
  routes = routes.concat(designSystemRoutes);
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.getters["session/isAuthenticated"];
  const currentUser = store.getters["users/user"];

  if (isAuthenticated) {
    if (!currentUser) {
      store.dispatch("session/recoverLogin");
    }
  }

  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next("/presignin");
  }

  next();
});

export default router;
