import { CanvasFile } from "@/models/wb/canvasFile.interface";
import * as wbFileService from "@/services/wb/wbFileService";
import { emptyFileId } from "@/services/configService";

async function generatePreview(url: string, file: CanvasFile): Promise<string> {
  // store.dispatch("wb/files/addCachedFile", { uuid: file.fileId, url });
  const canvas = document.createElement("canvas") as HTMLCanvasElement;
  const video = document.createElement("video") as HTMLVideoElement;
  const vmain = document.querySelector(".v-main__wrap");
  video.src = url;
  video.muted = true;
  vmain?.appendChild(video);
  await video.play();
  await video.pause();
  // manually set width and height don't impact final size and needed for snapshot. set to higher 19/ratio to keep quality of bigger pictures
  canvas.width = video.clientWidth;
  canvas.height = video.clientHeight;
  canvas.style.display = "block-inline";
  canvas.style.position = "absolute";
  canvas.style.top = "50%";
  canvas.style.left = "50%";
  canvas.style.transformOrigin = "center";
  video.muted = true;
  video.style.display = "block-inline";
  video.style.position = "absolute";
  video.style.top = "50%";
  video.style.left = "50%";
  video.style.transformOrigin = "center";

  if (video.clientWidth >= video.clientHeight) {
    file.size.width = video.clientWidth > 600 ? 600 : video.clientWidth;
    file.size.height = 600 * (video.clientHeight / video.clientWidth);
  } else {
    file.size.width = 600 * (video.clientWidth / video.clientHeight);
    file.size.height = video.clientHeight > 600 ? 600 : video.clientHeight;
  }

  vmain?.appendChild(canvas);
  const context = canvas.getContext("2d");
  context?.drawImage(video, 0, 0, canvas.width, canvas.height);
  video.parentNode?.removeChild(video);
  url = canvas.toDataURL("image/png");
  canvas.parentNode?.removeChild(canvas);
  return url;
}

export async function GetVideoElementDataUrl(
  file: CanvasFile
): Promise<string> {
  if (file.fileId == emptyFileId) return "";
  const objectUrl = await wbFileService.fetchFile(file.fileId);
  return await generatePreview(objectUrl, file);
}
